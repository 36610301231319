import Vue from "vue";
import VueX from "vuex";

Vue.use(VueX);
const store = new VueX.Store({
    state: {
        loading: false,
        cart: [],
        cartTotal: 0
    },
    mutations: {
        switchLoading(state, value) {
            state.loading = value;
        },
        updateCart(state, isLoading = false) {
            if (isLoading) {
                state.loading = true;
            }
            axios({
                method: "post",
                url: '/cart/get-all',
                data: {}
            }).then((response) => {
                state.cart = [];
                Object.entries(response.data).forEach(function (val, index) {
                    state.cart.push(val[1]);
                });
                if (isLoading) {
                    state.loading = false;
                }
            }).catch((err) => {

            });
        },
        getTotal(state) {
            axios({
                method: "post",
                url: '/cart/get-total',
                data: {}
            }).then((response) => {
                state.cartTotal = response.data;
            }).catch((err) => {

            });
        }
    }
});

export default store;
