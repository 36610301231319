<template>
    <form @submit.prevent="submitForm($event)">
        <div class="contact-us mt-5">
            <input type="password" autocomplete="off" class="contact-input w-100" v-model="old_password.value"
                   name="old_password" placeholder="رمز عبور فعلی خود را وارد کنید">
            <input type="password" autocomplete="off" class="contact-input" name="new_password"
                   v-model="new_password.value"
                   placeholder="رمز عبور جدید">
            <input type="password" autocomplete="off" class="contact-input" v-model="confirm_password.value"
                   name="new_password_confirmation"
                   placeholder="تکرار رمز عبور جدید">
            <button class="btn contact-us-btn mt-2">تغییر رمز عبور فعلی</button>
        </div>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                old_password: {value: "", className: ""},
                new_password: {value: "", className: ""},
                confirm_password: {value: "", className: ""}
            }
        },
        methods: {
            message(message, type = 'error') {
                Vue.$toast.open({
                    message: message,
                    type: type,
                    position: "top-left",
                    duration: 5000
                    // all other options
                });
            },
            messageSwal(message, type = 'error') {
                Swal.fire({
                    type: type,
                    title: message,
                    showConfirmButton: false,
                    timer: 2000
                })
            },
            clearBorders() {
                this.old_password.className = "";
                this.new_password.className = "";
                this.confirm_password.className = "";
            },
            submitForm(event) {
                let _this = this;
                let form_data = new FormData(event.target);
                _this.$store.commit('switchLoading', true);
                axios({
                    method: "post",
                    url: '/panel/change-pass',
                    data: form_data
                }).then((response) => {
                    _this.$store.commit('switchLoading', false);
                    _this.message('رمز عبور شما با موفقیت ویرایش شد', 'success');
                    _this.messageSwal('رمز عبور شما با موفقیت ویرایش شد', 'success');
                    _this.old_password.value = '';
                    _this.new_password.value = '';
                    _this.confirm_password.value = '';
                }).catch((err) => {
                    _this.$store.commit('switchLoading', false);
                    let errors = err.response.data.errors;
                    // old_password err
                    if (errors.hasOwnProperty('old_password')) {
                        _this.message(errors.old_password[0]);
                        _this.old_password.className = "border border-danger";
                    }
                    // new_password err
                    if (errors.hasOwnProperty('new_password')) {
                        _this.message(errors.new_password[0]);
                        _this.new_password.className = "border border-danger";
                    }
                    // confirm_password err
                    if (errors.hasOwnProperty('confirm_password')) {
                        _this.message(errors.confirm_password[0]);
                        _this.confirm_password.className = "border border-danger";
                    }
                });
            }
        }
    }
</script>