<template>


    <div class="position-relative mx-2">


        <form id="search-phone" action="/search" method="get">
            <input type="text" placeholder="جستوجو کنید" name="word" autocomplete="off" class="not-work-kh" id="input-phone" @keyup.prevent="onKeyUp()"
                   v-model="keyword">
            <button id="btn-search-phone" class="not-work-kh" type="submit">
                <i class="fa fa-search"></i>
            </button>
        </form>

        <div class="drop-search not-work-kh" v-if="isOpenPreview">
            <div class="CustomScroll" data-simplebar data-simplebar-auto-hide="false">


                <div class="text-center" v-if="loading"><i class="fas fa-spinner fa-spin display-4"></i></div>


                <div>
                    <div class="drop-search-item" v-for="(val,index) in list">
                        <a :href="val.link">
                            <span class="IRANSans-Light title">{{val.obj.title}}</span>
                            <span class="serach-box IRANSans-Medium" v-if="val.type == 'article'">مقاله</span>
                            <i class="icon-college-graduation show-md" v-if="val.type == 'article'"></i>
                            <span class="serach-box serach-box-color IRANSans-Medium"
                                  v-if="val.type == 'course'">آموزش</span>
                            <i class="icon-writing show-md" v-if="val.type == 'course'"></i>
                            <span class="serach-box serach-box-color2 IRANSans-Medium"
                                  v-if="val.type == 'podcast'">پادکست</span>
                            <i class="fas fa-video show-md" v-if="val.type == 'podcast'"></i>
                        </a>
                    </div>
                </div>


            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                keyword: "",
                list: [],
                loading: false,
                isOpenPreview: false
            }
        },
        mounted() {
            let _this = this;
            $('body').click(function (e) {
                _this.isOpenPreview = false;
            });
            $('.not-work-kh').click(function (e) {
                e.stopPropagation();
            });
        },
        methods: {
            onKeyUp() {
                let _this = this;
                this.loading = true;
                this.isOpenPreview = true;
                this.list = [];
                axios({
                    method: "post",
                    url: '/live-search',
                    data: {
                        keyword: _this.keyword
                    }
                }).then((response) => {
                    _this.loading = false;
                    _this.list = response.data;
                }).catch((err) => {
                    _this.$store.commit('switchLoading', false);
                    let errors = err.response.data.errors;
                });
            }
        }
    }
</script>