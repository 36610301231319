<template>
  <form @submit.prevent="onSubmit" class="forget-i">
    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
    <div class="mail-input">
      <input
        type="email"
        class="input-modal-login"
        v-model="email"
        placeholder="ایمیل خود را وارد کنید"
      />
      <i class="icon-mail2"></i>
    </div>
    <button type="submit" class="send-code IRANSans-Bold">
      ارسال کد فراموشی رمز
    </button>
    <google-re-captcha-v3
      v-model="gRecaptchaResponse"
      ref="captcha"
      hidden
      :site-key="$root.siteKey"
      id="forget_pass"
      action="forget_pass"
    ></google-re-captcha-v3>
  </form>
</template>

<script>
import { setTimeout } from "timers";
export default {
  data() {
    return {
      gRecaptchaResponse: "",
      email: "",
      isLoading: false,
      btnSubmit: "submit",
    };
  },
  methods: {
    onSubmit(event) {
      this.isLoading = true;
      this.btnSubmit = "button";
      let _this = this;
      let formData = new FormData();
      /* _this.email = _this.email.toLowerCase();
      _this.email = _this.email.split("@");
      console.log(_this.email);
      _this.email[0] = _this.email[0].replace(".", "");
      _this.email = _this.email[0]  + '@' +  _this.email[1]; */
      formData.append("email", _this.email);
      this.$refs.captcha.execute();
      formData.append("g-recaptcha-response", this.gRecaptchaResponse);

      axios
        .post("/password/email", formData)
        .then(function (res) {
          _this.isLoading = false;
          _this.btnSubmit = "submit";
          //console.log(res);
          if (res.data.ok) {
            Vue.$toast.open({
              message: "لینک بازیابی رمز عبور برای شما ارسال شد",
              type: "success",
              position: "top-left",
              // all other options
            });
          } else {
            Vue.$toast.open({
              message:
                "ایمیل قبلا ارسال شده است برای ارسال مجدد ایمیل بازیابی رمز عبور لطفا کمی صبر کنید",
              type: "error",
              position: "top-left",
              // all other options
            });
          }

          setTimeout(function () {
            location.reload();
          }, 2000);
        })
        .catch(function (err) {
          _this.isLoading = false;
          _this.btnSubmit = "submit";
          let errors = err.response.data.errors;
          let message = "";
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1500,
              // all other options
            });
            i++;
          }

        });
    },
  },
};
</script>
