<template>
    <form @submit.prevent="submitForm($event)">
        <div class="contact-us mt-5">
            <input type="text" class="contact-input w-100" name="title"
                   placeholder="عنوان پیام" v-model="title.value">
            <textarea rows="10" name="message" v-model="messagee.value" class="contact-comment mb-3"
                      placeholder="پیام شما"></textarea>
            <button class="btn contact-us-btn mt-2">ارسال پیام</button>
        </div>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                title: {value: "", className: ""},
                messagee: {value: "", className: ""}
            }
        },
        methods: {
            message(message, type = 'error') {
                Vue.$toast.open({
                    message: message,
                    type: type,
                    position: "top-left",
                    duration: 5000
                    // all other options
                });
            },
            clearBorders() {
                this.title.className = "";
                this.messagee.className = "";
            },
            submitForm(event) {
                let _this = this;
                let form_data = new FormData(event.target);
                _this.$store.commit('switchLoading', true);
                axios({
                    method: "post",
                    url: '/panel/save-new-ticket',
                    data: form_data
                }).then((response) => {
                    _this.$store.commit('switchLoading', false);
                    _this.message('پیام شما با موفقیت ثبت شد', 'success');
                    _this.title.value = '';
                    _this.messagee.value = '';
                    setTimeout(function () {
                        location.href='/panel/supports'
                    },1000);
                }).catch((err) => {
                    _this.$store.commit('switchLoading', false);
                    let errors = err.response.data.errors;
                    // title err
                    if (errors.hasOwnProperty('title')) {
                        _this.message(errors.title[0]);
                        _this.title.className = "border border-danger";
                    }
                    // message err
                    if (errors.hasOwnProperty('message')) {
                        _this.message(errors.message[0]);
                        _this.messagee.className = "border border-danger";
                    }
                });
            }
        }
    }
</script>