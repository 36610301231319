<template>
  <form @submit.prevent="onSubmit">
    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
    <input type="hidden" name="remember" value="1" />
    <div class="mail-input">
      <input
        id="email"
        v-model="email"
        type="email"
        class="input-modal-login"
        name="email"
        placeholder="ایمیل خود را وارد کنید"
      />
      <i class="icon-mail2"></i>
    </div>
    <div class="key-input">
      <input
        v-model="password"
        type="password"
        class="input-modal-login"
        name="password"
        placeholder="رمز عبور خود را وارد کنید"
      />
      <i class="icon-key icon-key-ab"></i>
    </div>
    <button :type="btnSubmit" class="send-comment mt-0">ورود به سایت</button>
    <div class="modal-input">
      <span>
        رمز عبور خود را فراموش کرده اید ؟
        <a
          href="#"
          data-toggle="modal"
          data-target="#my-login-modal2"
          class="forget-pass"
          >کلیک کنید.</a
        >
      </span>
    </div>
    <!--<div class="text-center m-3">
            <a href="#" @click.prevent="reloadCaptcha" class="btn btn-info"><i class="fas fa-sync"></i></a>
            <img :src="cImg">
        </div>-->
    <!--<div class="user-input">
            <input
                v-model="captcha"
                type="text"
                class="input-modal-login"
                name="captcha"
                placeholder="کد امنیتی را وارد نمایید"
                autocomplete="off"
            />
            <i class="fa fa-lock"></i>
        </div>-->
    <!--        <div class="google">
                    <div
                            id="recaptcha"
                            class="g-recaptcha"
                            data-sitekey="6LdGn64UAAAAAIeuNWQJ0OHq4Q0TH3vwLrmibq-G"
                    ></div>
                </div>-->
    <div class="col-12 my-hr">
      <span class="learn-h">یا</span>
      <hr />
    </div>
    <a href="/login/google" class="sign-google">
      <i class="fab fa-google-plus-g pl-2"></i> ورود با گوگل
    </a>
    <google-re-captcha-v3
      v-model="gRecaptchaResponse"
      ref="captcha"
      hidden
      :site-key="$root.siteKey"
      id="login"
      action="login"
    ></google-re-captcha-v3>
  </form>
</template>

<script>
export default {
  data() {
    return {
      gRecaptchaResponse: "",
      email: "",
      password: "",
      remember: "",
      isLoading: false,
      btnSubmit: "submit",
      captcha: "",
      cImg: "",
      token: "",
    };
  },
  mounted() {
    // this.reloadCaptcha();
  },
  methods: {
     reloadCaptcha() {
      let _this = this;
      axios.post("/get-new-captcha", {}).then(function (res) {
        _this.cImg = res.data;
        _this.captcha = "";
      });
    },
    onSubmit(event) {
      this.isLoading = true;
      this.btnSubmit = "button";
      let _this = this;

      this.$refs.captcha.execute();

      let formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);
      formData.append("remember", this.remember);
      formData.append("captcha", this.captcha);
      this.$refs.captcha.execute();
      formData.append("g-recaptcha-response", this.gRecaptchaResponse);

      axios
        .post("/login", formData)
        .then(function (res) {
          _this.reloadCaptcha();
          _this.isLoading = false;
          _this.btnSubmit = "submit";

          if (res.data.ok) {
            Vue.$toast.open({
              message: res.data.message,
              type: "success",
              position: "top-left",
              // all other options
            });
            setTimeout(function () {
              location.reload();
            }, 700);
          } else {
            Vue.$toast.open({
              message: res.data.message,
              type: "error",
              position: "top-left",
              duration: 2000,
              // all other options
            });
          }

          //location.reload();
        })
        .catch(function (err) {
          _this.isLoading = false;
          _this.btnSubmit = "submit";
          _this.reloadCaptcha();
          let errors = err.response.data.errors;
          let message = "";
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1500,
              // all other options
            });
            i++;
          }

          _this.$forceUpdate();
        });
    },
  },
};
</script>
