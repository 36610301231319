<template>
  <form @submit.prevent="submitForm($event)" class="cast-form">
    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
    <input
      v-if="user_id == '0'"
      type="text"
      class="input-podcast"
      name="name"
      v-model="name"
      placeholder="نام و نام خانوادگی"
    />
    <input
      v-if="user_id == '0'"
      type="email"
      class="input-podcast"
      name="email"
      v-model="email"
      placeholder="ایمیل خود را وارد کنید"
    />
    <textarea
      rows="6"
      name="body"
      placeholder="توضیحات خود را وارد کنید"
      v-model="body"
    ></textarea>
    <!-- <google-re-captcha-v3
      v-model="$root.rTokenC"
      :ref="'captcha' + this.parentid"
      :site-key="$root.siteKey"
      hidden
      action="comment"
    ></google-re-captcha-v3> -->
    <div class="d-flex justify-content-end w-100">
      <button :type="btnSubmit" class="send-comment">ثبت نظر</button>
    </div>
  </form>
</template>

<script>
import { setTimeout } from "timers";
export default {
  props: ["commentableid", "commentabletype", "userid", "parentid"],
  data() {
    return {
      gRecaptchaResponse: "",
      isLoading: false,
      btnSubmit: "submit",
      user_id: this.userid,
      parent_id: this.parentid,
      commentable_id: this.commentableid,
      commentable_type: this.commentabletype,
      name: "",
      email: "",
      body: "",
    };
  },

  methods: {
    submitForm: function ($event) {
      let _this = this;
      _this.isLoading = true;
      _this.btnSubmit = "button";
      let formData = new FormData($event.target);
      formData.append("commentable_id", _this.commentable_id);
      formData.append("commentable_type", _this.commentable_type);
      formData.append("parent_id", _this.parent_id);
      formData.append("user_id", _this.user_id);
      //   if (this.$root.rTokenC == "") {
      //   _this.$refs["captcha" + this.parentid].execute();
      //   }
      //   formData.append("g-recaptcha-response", this.$root.rTokenC);

      axios({
        method: "post",
        url: "/comment" + "?_method=POST",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          _this.btnSubmit = "submit";
          Vue.$toast.open({
            message:
              "با موفقیت ثبت شد و پس از تایید مدیریت به نمایش در خواهد آمد",
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });

          setTimeout(function () {
            _this.name = "";
            _this.email = "";
            _this.body = "";
            location.reload();
          }, 2000);
        })
        .catch(function (err) {
          _this.isLoading = false;
          _this.btnSubmit = "submit";
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },
  },
};
</script>
