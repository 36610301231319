<template>
  <form @submit.prevent="submitForm($event)">
    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
    <div class="contact-us">
      <input
        type="text"
        class="contact-input"
        name="name"
        v-model="name"
        placeholder="نام"
      />
      <input
        type="text"
        class="contact-input"
        name="family"
        v-model="family"
        placeholder="نام خانوادگی"
      />
      <input
        type="email"
        class="contact-input"
        name="email"
        v-model="email"
        placeholder="ایمیل"
      />
      <input
        type="text"
        class="contact-input"
        name="mobile"
        v-model="mobile"
        placeholder="شماره همراه"
      />
      <textarea
        rows="12"
        name="message"
        class="contact-comment"
        v-model="message"
        placeholder="پیام"
      ></textarea>
      <google-re-captcha-v3
        v-model="gRecaptchaResponse"
        ref="captcha"
        hidden
        :site-key="$root.siteKey"
        id="contact"
        action="contact"
      ></google-re-captcha-v3>
    </div>
    <button :type="btnSubmit" class="btn contact-us-btn">ارسال پیام</button>
  </form>
</template>
<script>
import { setTimeout } from "timers";

export default {
  data() {
    return {
      isLoading: false,
      btnSubmit: "submit",
      name: "",
      family: "",
      email: "",
      mobile: "",
      message: "",
      gRecaptchaResponse: "",
    };
  },
  mounted() {},
  created() {},
  methods: {
    submitForm: function ($event) {
      let _this = this;
      _this.isLoading = true;
      _this.btnSubmit = "button";
      let formData = new FormData($event.target);
      this.$refs.captcha.execute();
      formData.append("g-recaptcha-response", this.gRecaptchaResponse);
      formData.append("message", _this.message);
      axios({
        method: "post",
        url: "/contact" + "?_method=POST",
        data: formData,
      })
        .then(function (response) {
          _this.isLoading = false;
          _this.btnSubmit = "submit";
          Vue.$toast.open({
            message:
              "با موفقیت ثبت شد و به زودی از طرف مدیریت با شما تماس گرفته خواهد شد",
            type: "success",
            position: "top-left",
            duration: 2000,
            // all other options
          });
          grecaptcha.reset();
          //location.reload();
          _this.name = "";
          _this.family = "";
          _this.email = "";
          _this.mobile = "";
          _this.message = "";
        })
        .catch(function (err) {
          grecaptcha.reset();
          _this.isLoading = false;
          _this.btnSubmit = "submit";
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },
  },
};
</script>
