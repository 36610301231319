<template>
  <form @submit.prevent="submitFrm($event)">
    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
    <div class="user-input">
      <input
        type="text"
        class="input-modal-login"
        name="name"
        placeholder="نام و نام خانوادگی"
      />
      <i class="icon-user icon-user-ab"></i>
    </div>
    <div class="mail-input">
      <input
        type="email"
        class="input-modal-login"
        name="email"
        placeholder="ایمیل خود را وارد کنید"
      />
      <i class="icon-mail2"></i>
    </div>
    <div class="key-input">
      <input
        type="text"
        autocomplete="off"
        class="input-modal-login"
        name="phone"
        placeholder="شماره همراه خود را وارد کنید"
      />
      <i class="fa fa-mobile"></i>
    </div>
    <div v-if="t == 'free'">
      <label for="regEventFile" class="btn btn-warning btn-block p-2 text-white"
        >انتخاب فایل رزومه (rar - zip)</label
      >
      <input type="file" name="file" class="d-none" id="regEventFile" />
    </div>
    <div class="text-center m-3">
      <a href="#" @click.prevent="reloadCaptcha" class="btn btn-info"
        ><i class="fas fa-sync"></i
      ></a>
      <img :src="cImg" />
    </div>
    <div class="user-input">
      <input
        v-model="captcha"
        type="text"
        class="input-modal-login"
        name="captcha"
        placeholder="کد امنیتی را وارد نمایید"
        autocomplete="off"
      />
      <i class="fa fa-lock"></i>
    </div>
    <google-re-captcha-v3
      v-model="gRecaptchaResponse"
      ref="captcha"
      hidden
      :site-key="$root.siteKey"
      id="event"
      action="event"
    ></google-re-captcha-v3>
    <button class="send-comment">پرداخت و تکمیل سفارش</button>
  </form>
</template>

<script>
export default {
  props: ["id", "t"],
  data() {
    return {
      gRecaptchaResponse: "",
      captcha: "",
      cImg: "",
      isLoading: false,
    };
  },
  mounted() {
    this.reloadCaptcha();
  },
  methods: {
    reloadCaptcha() {
      let _this = this;
      axios.post("/get-new-captcha", {}).then(function (res) {
        _this.cImg = res.data;
        _this.captcha = "";
      });
    },
    message(message, type = "error") {
      Vue.$toast.open({
        message: message,
        type: type,
        position: "top-left",
        duration: 5000,
        // all other options
      });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    submitFrm(event) {
      let _this = this;
      let frmData = new FormData(event.target);
      frmData.append("id", _this.id);
      frmData.append("t", _this.t);
      this.$refs.captcha.execute();
      formData.append("g-recaptcha-response", this.gRecaptchaResponse);
      _this.isLoading = true;
      axios({
        method: "post",
        url: "/add/user/event",
        data: frmData,
      })
        .then((response) => {
          _this.isLoading = false;
          _this.reloadCaptcha();

          if (response.data === "success") {
            location.href = "/gate/event";
            _this.message("... در حال اتصال به درگاه پرداخت", "success");
          }
          if (response.data === "itsFree") {
            _this.message("شما در رویداد ثبت نام شدید", "success");
          }
        })
        .catch((err) => {
          _this.isLoading = false;
          let errors = err.response.data.errors;
          //console.log(errors);
          let i = 1;
          _this.reloadCaptcha();
          for (let key in errors) {
            setTimeout(function () {
              Vue.$toast.open({
                message: errors[key][0],
                type: "error",
                position: "top-left",
                duration: 7000,
                // all other options
              });
            }, i * 300);
            i++;
          }
        });
    },
  },
};
</script>
