<template>
  <div>
    <div class="user-basket-wrap">
      <div class="basket new-basket-open">
        <a href="#">
          <i class="nova icon-basket"></i>
          <span class="h-num-basket">{{ cart.length }}</span>
          <span class="h-basket">سبد خرید</span>
        </a>
      </div>
    </div>
    <div class="basket-container basket-container2 new-b-con">
      <div class="basket-menu">
        <div class="link-menu">
          <span class="basket-menu-link IRANSans-Bold">سبد خرید شما</span>
          <span class="basket-menu-link2 IRANSans-Light"
            >{{ cart.length }} دوره</span
          >
        </div>

        <div class="basket-shopping" v-if="cart.length < 1">
          <i class="nova icon-shopping-bag"></i>
          <span class="empty-basket">سبد خرید شما خالی است</span>
          <span class="more-learn">آموزش های بیشتری را اضافه کنید</span>
        </div>

        <div
          class="basket-full mt-4"
          v-if="cart.length > 0"
          v-for="(value, inedx) in cart"
        >
          <a :href="value.link">
            <div class="flex-align-center justify-content-between">
              <div class="basket-full-box">
                <img :src="value.image" :alt="value.title" />
                <div class="basket-learn">
                  <span>{{ value.title }}</span>
                  <span class="tedad IRANSans-Medium"
                    >{{ value.count }} ×
                    {{
                      numberWithCommas(
                        parseInt(value.price) == 0
                          ? parseInt(value.price)
                          : value.getType == "post"
                          ? parseInt(value.price) + parseInt(value.post_price)
                          : value.getType == "flash"
                          ? parseInt(value.price) + parseInt(value.flash_price)
                          : parseInt(value.price)
                      )
                    }}
                    تومان</span
                  >
                </div>
              </div>
              <i
                class="fas fa-times basket-full-box-i"
                @click.prevent="removeFromCart(value.id)"
              ></i>
            </div>
          </a>
          <hr class="basket-hr" />
        </div>

        <!--   <div class="link-menu" v-if="cart.length>0 && cartTotal.isPost=='yes'">
                    <span class="basket-menu-link IRANSans-Bold">مجموع:</span>
                    <span
                        class="basket-money IRANSans-Medium">{{numberWithCommas(cartTotal.totalWithOutPost)}} تومان</span>
                </div>
                <div class="link-menu" v-if="cart.length>0 && cartTotal.isPost=='yes'">
                    <span class="basket-menu-link IRANSans-Bold">هزینه ارسال:</span>
                    <span class="basket-money IRANSans-Medium">{{numberWithCommas(cartTotal.postPrice)}} تومان</span>
                </div> -->
        <div class="link-menu" v-if="cart.length > 0">
          <span class="basket-menu-link IRANSans-Bold">قیمت نهایی:</span>
          <span class="basket-money IRANSans-Medium"
            >{{ numberWithCommas(cartTotal.total) }} تومان</span
          >
        </div>

        <div class="bottom-basket" v-if="cart.length > 0">
          <a href="/cart/show" class="btn-basket">مشاهده سبد خرید</a>
          <!--                    <a href="/cart/final" class="btn-basket">تسویه حساب</a>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("updateCart");
    this.$store.commit("getTotal");
    this.$forceUpdate();
  },
  methods: {
    message(message, type = "error") {
      Vue.$toast.open({
        message: message,
        type: type,
        position: "top-left",
        duration: 5000,
        // all other options
      });
    },
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    removeFromCart(id) {
      let _this = this;
      Swal.fire({
        title: "حذف دوره",
        text: "آيا از حذف این دوره از سبد خرید اطمینان دارید؟",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "آره حذف کن",
        cancelButtonText: "نه",
      }).then((result) => {
        if (result.value) {
          _this.$store.commit("switchLoading", true);
          axios({
            method: "post",
            url: "/cart/remove",
            data: {
              id: id,
            },
          })
            .then((response) => {
              _this.$store.commit("switchLoading", false);
              _this.$store.commit("updateCart");
              _this.$store.commit("getTotal");
              _this.message("دوره مورد نظر حذف شد", "success");
              _this.$forceUpdate();
            })
            .catch((err) => {
              _this.$store.commit("switchLoading", false);
              _this.message("خطا در ذخیره سازی اطلاعات");
            });
        }
      });
    },
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    },
    cartTotal() {
      return this.$store.state.cartTotal;
    },
  },
};
</script>
