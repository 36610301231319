<template>
    <div class="container">
        <div class="broken-shopping">
            <div class="shopping-right-box">
                <div class="introduction-cource2">
                    <span class="title2">جزئیات صورت حساب</span>
                    <hr/>
                </div>

                <div class="s-podcast-form m-0 p-0">
                    <form action="" class="cast-form2">
                        <input
                            type="text"
                            class="input-podcast margin-input kh-100-imp"
                            v-model="name"
                            placeholder="نام و نام خانوادگی (الزامی)"
                        />
                        <!--                        <input type="text" class="input-podcast margin-input" v-model="last_name"
                                                                   placeholder="نام خانوادگی">-->
                        <input
                            type="text"
                            class="input-podcast w-100 margin-input"
                            v-model="mobile"
                            placeholder="شماره تلفن همراه"
                        />
                        <span class="peygiri"
                        >برای پیگیری محصول خریداری شده، شماره همراه الزامی است</span
                        >
                        <input
                            type="email"
                            class="input-podcast w-100 margin-input"
                            v-model="email"
                            v-if="isLogin"
                            disabled
                            readonly
                            placeholder="ایمیل خود را وارد کنید"
                        />
                        <input
                            type="email"
                            class="input-podcast w-100 margin-input"
                            v-model="email"
                            v-if="!isLogin"
                            placeholder="ایمیل خود را وارد کنید"
                        />

                        <select v-if="isAllDn" class="input-podcast w-100 margin-input"
                                @change="ChangeProvince($event)">
                            <option value="" disabled hidden selected>نام استان (الزامی)</option>
                            <option v-for="item in list_province" :value="item.id"
                                    :selected="item.id == province ? true : false"
                            >{{ item.name }}
                            </option>
                        </select>

                        <select v-model="city" v-if="province && isAllDn" class="input-podcast w-100 margin-input">
                            <option value="" disabled hidden>نام شهر (الزامی)</option>
                            <option
                                v-for="item in list_city"
                                :value="item.id"
                                :selected="item.id == city ? true : false"
                            >{{ item.name }}
                            </option>
                        </select>

                        <input
                            type="text"
                            class="input-podcast w-100 margin-input"
                            v-model="address"
                            v-if="isAllDn"
                            placeholder="آدرس دقیق خود را وارد کنید"
                        />
                        <!--<input type="text" class="input-podcast margin-input" name="city" placeholder="شهر">-->
                        <input
                            type="number"
                            class="input-podcast margin-input kh-100-imp"
                            v-model="postal_code"
                            v-if="isAllDn"
                            placeholder="کدپستی"
                        />
                    </form>
                </div>
            </div>

            <div class="shopping-left-box">
                <div class="introduction-cource2">
                    <span class="title2">توضیحات سفارش</span>
                    <hr/>
                </div>

                <div class="s-podcast-form m-0 p-0">
                    <form action="" class="cast-form2">
            <textarea
                rows="10"
                name="comment"
                class="rounded-lg"
                v-model="description"
                placeholder="توضیحات خود را درباره ی محصول وارد کنید (اختیاری)"
            ></textarea>
                    </form>
                </div>
            </div>
        </div>

        <div class="your-shopping">
            <div class="introduction-cource2">
                <span class="title2">سفارش شما</span>
                <hr/>
            </div>

            <div class="table-responsive shop-table">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col" class="shop-title">محصول</th>
                        <th scope="col" class="shop-title">مجموع</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(val, index) in cart">
                        <th>
                            <a :href="val.link" target="_blank" class="shop-link">{{
                                    val.title
                                }}</a>
                        </th>
                        <th class="shoping-money">
                            <span v-if="val.discount_code == '' &&
                      val.visitor_discount_code == ''">
                                {{
                                    numberWithCommas(
                                        parseInt(val.price) == 0
                                            ? parseInt(val.price)
                                            : val.getType == "post"
                                                ? parseInt(val.price) + parseInt(val.post_price)
                                                : val.getType == "flash"
                                                    ? parseInt(val.price) + parseInt(val.flash_price)
                                                    : parseInt(val.price)
                                    )
                                }}
                            </span>
                            <span v-else>
                                    {{
                                    numberWithCommas(((parseInt(val.price) - ((parseInt(val.price) / 100) * val.percent))
                                            +
                                            (val.getType == "post" ? parseInt(val.post_price) : val.getType == "flash" ? parseInt(val.flash_price) : 0)
                                        )
                                        * val.count
                                    )
                                }}
                                    تومان</span>
                        </th>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="update-shopping">
            <div class="up-money mr-auto">
                <!--                <span class="price" v-if="cartTotal.isPost == 'yes'">مجموع</span>-->
                <!--                <span class="all-price" v-if="cartTotal.isPost == 'yes'"-->
                <!--                >{{ numberWithCommas(cartTotal.totalWithOutPost) }} تومان</span-->
                <!--                >-->
                <!--                <span class="price" v-if="cartTotal.isPost == 'yes'">هزینه ارسال</span>-->
                <!--                <span class="all-price" v-if="cartTotal.isPost == 'yes'"-->
                <!--                >{{ numberWithCommas(cartTotal.postPrice) }} تومان</span-->
                <!--                >-->
            </div>
            <div class="up-money">
                <span class="price">قیمت نهایی</span>
                <span class="all-price"
                >{{ numberWithCommas(cartTotal.total) }} تومان</span
                >
            </div>
        </div>
        <google-re-captcha-v3
            v-model="gRecaptchaResponse"
            ref="captcha"
            hidden
            :site-key="$root.siteKey"
            id="fintal_cart"
            action="final_cart"
        ></google-re-captcha-v3>
        <div class="update-shopping-card">
            <div class="payment-method">
                <span>روش پرداخت</span>
                <div class="input-row2">
                    <input
                        id="radio-input-2"
                        type="radio"
                        value="myValue"
                        name="radio-group"
                        checked
                    />
                    <label for="radio-input-2" class="input-helper input-helper--radio">
                        <img src="/img/zibal.png" alt="zarin pal - apademi"/> پرداخت امن
                        و مطمئن با زیبال</label
                    >
                </div>
            </div>
            <button class="update-basket payment" @click.prevent="goToGate()" :disabled="isDisabled">
                ادامه و پرداخت
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            gRecaptchaResponse: "",
            gate: "zarinpal",
            name: "",
            //             last_name: "",
            mobile: "",
            email: "",
            list_province: [],
            list_city: [],
            province: "",
            city: "",
            address: "",
            postal_code: "",
            description: "",
            isLogin: false,
            isDisabled: false
        };
    },
    created() {
        let _this = this;
        axios({
            method: "post",
            url: "/cart/get-user",
            data: {},
        })
            .then((response) => {
                if (response.data !== "notlogin") {
                    /*if (response.data.family == null) {
                        _this.name = response.data.name;
                    } else {
                        _this.name = response.data.name + " " + response.data.family;
                    }*/

                    //_this.last_name = response.data.family;
                    _this.mobile = response.data.mobile;
                    _this.province = response.data.province_id;
                    _this.city = response.data.city_id;
                    _this.email = response.data.email;
                    _this.address = response.data.address;
                    _this.postal_code = response.data.postal_code;
                    _this.isLogin = true;

                    axios({
                        method: "post",
                        url: "/cart/get-province",
                        data: {},
                    })
                        .then((response) => {
                            _this.list_province = response.data;
                        });

                    if (_this.province) {
                        axios({
                            method: "post",
                            url: "/cart/get-city",
                            data: {
                                id: _this.province
                            },
                        }).then((response) => {
                            _this.list_city = response.data;
                        });
                    }

                }
            });
    },
    mounted() {
        this.$store.commit("updateCart");
        this.$store.commit("getTotal");
        this.$forceUpdate();
    },
    methods: {
        ChangeProvince(event) {
            let _this = this;
            _this.city = "";
            _this.list_city = [];
            _this.province = event.target.value;
            axios({
                method: "post",
                url: "/cart/get-city",
                data: {
                    id: _this.province
                },
            })
                .then((response) => {
                    _this.list_city = response.data;
                })
                .catch((err) => {
                });
        },
        message(message, type = "error") {
            Vue.$toast.open({
                message: message,
                type: type,
                position: "top-left",
                duration: 5000,
                // all other options
            });
        },
        goToGate() {
            let _this = this;
            _this.isDisabled = true;
            _this.$store.commit("switchLoading", true);
            let data = {
                first_name: _this.name,
                //last_name: _this.last_name,
                gate: _this.gate,
                mobile: _this.mobile,
                email: _this.email,
                description: _this.description,
                "g-recaptcha-response": _this.gRecaptchaResponse,
            };
            if (_this.isAllDn) {
                data.postal_code = _this.postal_code;
                data.address = _this.address;
                data.province = _this.province;
                data.city = _this.city;
            }
            this.$refs.captcha.execute();
            axios({
                method: "post",
                url: "/cart/got-to-gate",
                data,
            })
                .then((response) => {
                    _this.$store.commit("switchLoading", false);
                    if (
                        response.data.status == "success" &&
                        response.data.action == "pay"
                    ) {
                        location.href = response.data.link;
                    } else if (
                        response.data.status == "success" &&
                        response.data.action == "free"
                    ) {
                        _this.message("دوره ها خریداری شدند", "success");
                        setTimeout(function () {
                            location.href = "/panel/courses";
                        }, 2000);
                    } else if (
                        response.data.status == "success" &&
                        response.data.action == "temp"
                    ) {
                        location.href = "/temp";
                    } else {
                        _this.isDisabled = false;
                        _this.message("خطا در اتصال به درگاه پرداخت");
                    }
                })
                .catch((err) => {
                    _this.$store.commit("switchLoading", false);
                    _this.isDisabled = false;
                    let errors = err.response.data.errors;
                    for (let key in errors) {
                        _this.message(errors[key][0]);
                    }
                });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        myReload() {
            this.$store.commit("updateCart");
            this.$store.commit("getTotal");
        },
    },
    computed: {
        cart() {
            return this.$store.state.cart;
        },
        cartTotal() {
            return this.$store.state.cartTotal;
        },
        isAllDn() {
            let isDl = false;
            for (let cartKey in this.cart) {
                if (this.cart[cartKey].getType == "post") {
                    isDl = true;
                }
            }
            return isDl;
        },
    },
};
</script>


<style>
.kh-100-imp {
    width: 100% !important;
}
</style>
