<template>
  <form @submit.prevent="onSubmit">
    <square class="h-spiner" color="#17c549" v-show="isLoading"></square>
    <div class="user-input">
      <input
        v-model="name"
        type="text"
        class="input-modal-login"
        name="name"
        placeholder="نام و نام خانوادگی"
      />
      <i class="icon-user icon-user-ab"></i>
    </div>
    <div class="mail-input">
      <input
        v-model="email"
        type="email"
        class="input-modal-login"
        name="email"
        placeholder="ایمیل خود را وارد کنید"
      />
      <i class="icon-mail2"></i>
    </div>
    <div class="mail-input">
      <input
        v-model="phone"
        type="number"
        class="input-modal-login"
        name="phone"
        placeholder="شماره موبایل خود را وارد کنید"
      />
      <i class="fa fa-phone"></i>
    </div>
    <div class="key-input">
      <input
        v-model="password"
        type="password"
        class="input-modal-login"
        name="password"
        placeholder="رمز عبور خود را وارد کنید"
      />
      <i class="icon-key icon-key-ab"></i>
    </div>

    <div class="modal-input">
      <div class="input-row">
        <!--                <input v-model="rules" id="checkbox-input-2" type="checkbox" name="rules"/>-->
        <!-- <label class="input-helper input-helper&#45;&#45;radio">
           <span>
             با
             <a href="/rules">قوانین و مقررات</a> آپادمی
             موافقم
           </span>
                 </label>-->
        <span
          >ثبت نام در سایت به منزله قبول <a href="/rules">قوانین</a> است</span
        >
      </div>
    </div>

    <!--<div class="text-center m-3">
            <a href="#" @click.prevent="reloadCaptcha" class="btn btn-info"><i class="fas fa-sync"></i></a>
            <img :src="cImg">
        </div>-->
    <!--<div class="user-input">
            <input
                v-model="captcha"
                type="text"
                class="input-modal-login"
                name="captcha"
                placeholder="کد امنیتی را وارد نمایید"
                autocomplete="off"
            />
            <i class="fa fa-lock"></i>
        </div>-->
    <!--<div class="google">
            <div class="google">
                <div
                        id="recaptcha"
                        class="g-recaptcha"
                        data-sitekey="6LdGn64UAAAAAIeuNWQJ0OHq4Q0TH3vwLrmibq-G"
                ></div>
            </div>
        </div>-->

    <button :type="btnSubmit" class="send-comment">عضویت در سایت</button>
    <google-re-captcha-v3
      v-model="gRecaptchaResponse"
      ref="captcha"
      hidden
      :site-key="$root.siteKey"
      id="register"
      action="register"
    ></google-re-captcha-v3>
  </form>
</template>

<script>
import { setTimeout } from "timers";

export default {
  data() {
    return {
      gRecaptchaResponse: "",
      email: "",
      password: "",
      rules: "",
      name: "",
      isLoading: false,
      btnSubmit: "submit",
      captcha: "",
      cImg: "",
      phone: "",
    };
  },
  mounted() {
    this.reloadCaptcha();
  },
  methods: {
    reloadCaptcha() {
      let _this = this;
      axios.post("/get-new-captcha", {}).then(function (res) {
        _this.cImg = res.data;
        _this.captcha = "";
      });
    },
    onSubmit(event) {
      this.isLoading = true;
      this.btnSubmit = "button";
      let _this = this;
      let formData = new FormData();
      formData.append("email", this.email);
      formData.append("name", this.name);
      formData.append("password", this.password);
      formData.append("mobile", this.phone);
      formData.append("rules", this.rules);
      //   formData.append("captcha", this.captcha);
      this.$refs.captcha.execute();
      formData.append("g-recaptcha-response", this.gRecaptchaResponse);

      axios
        .post("/register", formData)
        .then(function (res) {
          _this.reloadCaptcha();
          _this.isLoading = false;
          _this.btnSubmit = "submit";
          /*Vue.$toast.open({
                            message: "لینک فعالسازی به ایمیل شما ارسال شد برای فعالسازی حساب کاربری و تایید ایمیل روی لینک فرستاده شده به ایمیل خود کلیک کنید و سپس اقدام به ورود نمایید",
                            type: "success",
                            position: "top-left",
                            duration: 50000
                            // all other options
                        });*/
          Vue.$toast.open({
            message: "شما با موفقیت در سایت ثبتنام شدید",
            type: "success",
            position: "top-left",
            duration: 50000,
            // all other options
          });
          setTimeout(function () {
            location.reload();
          }, 3000);
        })
        .catch(function (err) {
          _this.reloadCaptcha();
          _this.isLoading = false;
          _this.btnSubmit = "submit";
          let errors = err.response.data.errors;
          let i = 1;
          for (let key in errors) {
            Vue.$toast.open({
              message: errors[key][0],
              type: "error",
              position: "top-left",
              duration: i * 1000,
              // all other options
            });
            i++;
          }
        });
    },
  },
};
</script>
