<template>
    <div class="information-box" id="profile">
        <div class="title-border">
            <span class="title-title">مشخصات کاربری</span>
            <hr>
        </div>

        <div class="my-avatar">
            <!-- code here -->
            <div class="progress-container">
                <div class="nitin nitin2">
                    <canvas id="my_canvas2" data-percent="80" width="150" height="150"></canvas>
                    <div id="my_canvas_icon">
                        <i class="fas fa-cloud-download"></i>
                    </div>
                    <div class="my_canvas_border"></div>
                    <input type="file" @change.prevent="uploadImage($event)" id="kh_upload_avatar">
                    <img :src="avatar.value" class="tech-logo" :alt="first_name.value+' '+last_name.value">
                </div>
            </div>
            <!-- code here -->

            <div class="user-profile-span">
                <span>تصویر مورد نظر خود را انتخاب کنید.</span>
                <span>ابعاد مجاز: 200 در 200</span>
                <span>فرمت های مجاز: Jpg, Png</span>
            </div>

        </div>

        <div class="rules w-100 p-0">
            <form @submit.prevent="saveInfo">
                <div class="contact-us mt-5">
                    <input type="text" class="contact-input"
                           :class="first_name.className"
                           v-model="first_name.value" name="first_name"
                           placeholder="نام">
                    <input type="text" class="contact-input"
                           :class="last_name.className"
                           v-model="last_name.value" name="last_name"
                           placeholder="نام خانوادگی">
                    <input type="email" class="contact-input"
                           :class="email.className"
                           readonly
                           disabled
                           v-model="email.value" name="email" placeholder="ایمیل">
                    <input type="number" class="contact-input"
                           :class="mobile.className"
                           v-model="mobile.value" name="mobile"
                           placeholder="شماره همراه">
                    <textarea rows="2" name="address"
                              :class="address.className"
                              v-model="address.value" class="contact-comment mb-3"
                              placeholder="آدرس"></textarea>
                    <input type="number" class="contact-input"
                           :class="phone.className"
                           v-model="phone.value" name="phone"
                           placeholder="تلفن ثابت">
                    <input type="number" class="contact-input"
                           :class="postal_code.className"
                           v-model="postal_code.value" name="postal_code"
                           placeholder="کد پستی">

                    <button class="btn btn-success p-3">ذخیره اطلاعات</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                first_name: {value: "", className: "", message: "", point: 10, isPoint: false},
                last_name: {value: "", className: "", message: "", point: 10, isPoint: false},
                email: {value: "", className: "", message: "", point: 20, isPoint: false},
                mobile: {value: "", className: "", message: "", point: 20, isPoint: false},
                address: {value: "", className: "", message: "", point: 15, isPoint: false},
                phone: {value: "", className: "", message: "", point: 10, isPoint: false},
                postal_code: {value: "", className: "", message: "", point: 15, isPoint: false},
                avatar: {value: "", className: "", message: ""},
                id: null,
                point: 0,
                timer: null
            }
        },
        created() {
            let _this = this;
            axios({
                method: "post",
                url: '/panel/get-user',
                data: {}
            }).then((response) => {
                if (response.data == null) {
                    location.href = '/';
                } else {
                    _this.id = response.data.id;
                    _this.first_name.value = response.data.name;
                    _this.last_name.value = response.data.family;
                    _this.email.value = response.data.email;
                    _this.mobile.value = response.data.mobile;
                    _this.phone.value = response.data.phone;
                    _this.postal_code.value = response.data.postal_code;
                    _this.address.value = response.data.address;
                    _this.avatar.value = response.data.avatar.original;
                    this.point = parseInt($("#my_canvas").attr('data-percent'));
                }
            });
        },
        mounted() {
            $("#my_canvas_icon").click(function (e) {
                e.preventDefault();
                $("#kh_upload_avatar").val('');
                $("#kh_upload_avatar").trigger('click');
            });
            //this.point = $("#my_canvas").attr('data-percent');
        },
        methods: {
            message(message, type = 'error') {
                Vue.$toast.open({
                    message: message,
                    type: type,
                    position: "top-left",
                    duration: 5000
                    // all other options
                });
            },
            changeProgress(percent) {
                $("#my_canvas").attr('data-percent', percent);
                window.al = 0;
                clearInterval(window.sim);
                window.sim = setInterval(progressSim, 30);
            },
            uploadImage(e) {
                let _this = this;
                Swal.fire({
                    title: 'تعویض عکس',
                    text: "از تعویض عکس اطمینان دارید؟",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'آره عوض بشه',
                    cancelButtonText: 'نه'
                }).then((result) => {
                    if (result.value) {
                        const file = e.target.files[0];
                        let form_data = new FormData();
                        form_data.append('file', file);
                        form_data.append('id', _this.id);
                        _this.$store.commit('switchLoading', true);
                        axios({
                            method: "post",
                            url: '/panel/set-avatar',
                            data: form_data
                        }).then((response) => {
                            _this.$store.commit('switchLoading', false);
                            _this.avatar.value = response.data.image;
                            $('.userImage').attr('src', response.data.image);
                            _this.message('عکس پروفایل با موفقیت تغیر یافت', 'success');
                        }).catch((err) => {
                            _this.$store.commit('switchLoading', false);
                            let errors = err.response.data.errors;
                            // file err
                            if (errors.hasOwnProperty('file')) {
                                _this.message(errors.file[0]);
                                //_this.file.message = errors.title[0];
                            }
                        });

                    }
                })
            },
            clearBorders() {
                this.first_name.className = "";
                this.last_name.className = "";
                this.email.className = "";
                this.mobile.className = "";
                this.phone.className = "";
                this.postal_code.className = "";
                this.address.className = "";
            },
            saveInfo(type) {
                let _this = this;
                clearTimeout(_this.timer);
                _this.$store.commit('switchLoading', true);
                _this.timer = setTimeout(function () {
                    let params = {
                        id: _this.id,
                        type: type
                    };
                    _this.clearBorders();
                    /*switch (type) {
                        case "name":*/
                    params.name = _this.first_name.value;
                    /*     break;
                     case "family":*/
                    params.family = _this.last_name.value;
                    /* break;
                 case "email":*/
                    params.email = _this.email.value;
                    /*    break;
                    case "mobile":*/
                    params.mobile = _this.mobile.value;
                    /* break;
                 case "phone":*/
                    params.phone = _this.phone.value;
                    /*break;
                case "postal_code":*/
                    params.postal_code = _this.postal_code.value;
                    /*  break;
                  case "address":*/
                    params.address = _this.address.value;
                    /* break;
                 default:
                     return false;
             }*/
                    axios({
                        method: "post",
                        url: '/panel/save-user-info',
                        data: params
                    }).then((response) => {
                        _this.$store.commit('switchLoading', false);
                        _this.timer = setTimeout(function () {
                            _this.changeProgress(response.data.point);
                        }, 2000);
                        /* switch (type) {
                             case "name":*/
                        $(".name-change-ajax").html((_this.first_name.value ? _this.first_name.value : '') + ' ' + (_this.last_name.value ? _this.last_name.value : ""));
                        _this.message('اطلاعات شما با موفقیت ویرایش شد', 'success');
                        /*   break;
                       case "family":*/
                        // $(".name-change-ajax").html((_this.first_name.value ? _this.first_name.value : '') + ' ' + (_this.last_name.value ? _this.last_name.value : ""));
                        /* break;
                     default:
                         return false;
                 }*/
                    }).catch((err) => {
                        _this.$store.commit('switchLoading', false);
                        let errors = err.response.data.errors;
                        // name err
                        if (errors.hasOwnProperty('name')) {
                            _this.timer = setTimeout(function () {
                                _this.message(errors.name[0]);
                                _this.first_name.className = "border border-danger";
                            }, 2000);
                        }
                        // family err
                        if (errors.hasOwnProperty('family')) {
                            _this.timer = setTimeout(function () {
                                _this.message(errors.family[0]);
                                _this.family.className = "border border-danger";
                            }, 2000);
                        }
                        // email err
                        if (errors.hasOwnProperty('email')) {
                            _this.timer = setTimeout(function () {
                                _this.message(errors.email[0]);
                                _this.email.className = "border border-danger";
                            }, 2000);
                        }
                        // mobile err
                        if (errors.hasOwnProperty('mobile')) {
                            _this.timer = setTimeout(function () {
                                _this.message(errors.mobile[0]);
                                _this.mobile.className = "border border-danger";
                            }, 2000);
                        }
                        // phone err
                        if (errors.hasOwnProperty('phone')) {
                            _this.timer = setTimeout(function () {
                                _this.message(errors.phone[0]);
                                _this.phone.className = "border border-danger";
                            }, 2000);
                        }
                        // postal_code err
                        if (errors.hasOwnProperty('postal_code')) {
                            _this.timer = setTimeout(function () {
                                _this.message(errors.postal_code[0]);
                                _this.postal_code.className = "border border-danger";
                            }, 2000);
                        }
                        // address err
                        if (errors.hasOwnProperty('address')) {
                            _this.timer = setTimeout(function () {
                                _this.message(errors.address[0]);
                                _this.address.className = "border border-danger";
                            }, 2000);
                        }
                    });
                }, 400);
            }
        }
    }
</script>

<style scoped>
    #my_canvas_icon {
        cursor: pointer;
    }

    #kh_upload_avatar {
        display: none;
    }
</style>
