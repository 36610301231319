<template>
  <div class="information-box padding-comment-supp">
    <div class="title-border-support">
      <span class="title-title-support">{{ question.title }}</span>
      <a href="#" class="close-request" @click.prevent="closeTicket()"
        >بستن درخواست</a
      >
      <hr class="supp-hr" />
    </div>

    <div class="s-cast-comment pt-0 padding-comment-supp">
      <div>
        <P v-html="nl2br(question.message)"></P>
      </div>

      <div class="col-12 my-hr mt-4">
        <span class="learn-h replay-supp">پاسخ پشتیبانی</span>
        <hr />
      </div>

      <div
        class="user-comment-replay padding-comment-supp"
        v-for="(val, index) in question.answers"
      >
        <div class="s-podcast-title">
          <div class="my-comment admin-comment">
            <img :src="val.user.avatar['38']" />
            <div class="command-user-name">
              <span class="admin-user"
                >{{ val.user.name }} {{ val.user.family }}</span
              >
              <span class="admin-en-user" v-if="val.user.level == 'admin'"
                >مدیر آپادمی</span
              >
              <span class="admin-en-user" v-if="val.user.level == 'user'"
                >کاربر آپادمی</span
              >
              <span class="admin-en-user" v-if="val.user.level == 'teacher'"
                >مدرس آپادمی</span
              >
            </div>
          </div>

          <div class="time-replay text-white">
            <span>{{ val.time }}</span>
            <!-- Modal replay comment -->
            <div
              class="replay-comment"
              data-toggle="modal"
              data-target="#exampleModalCentered"
            >
              <span class="replay text-white text-left">پاسخ</span
              ><i class="text-white icon-replay-symbol"></i>
            </div>

            <div
              class="modal fade bd-example-modal-lg"
              id="exampleModalCentered"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenteredLabel"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenteredLabel">
                      پاسخ به نظر:
                    </h5>
                    <button
                      type="button"
                      class="close comm-margin-modal"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true"
                        ><i class="fa fa-times"></i
                      ></span>
                    </button>
                  </div>
                  <div class="modal-body p-0">
                    <div class="container">
                      <div class="s-podcast-form mt-0">
                        <form
                          @submit.prevent="submitForm($event)"
                          class="cast-form mt-0"
                        >
                          <textarea
                            rows="6"
                            name="comment"
                            placeholder="جواب خود را بنویسید"
                            v-model="answer"
                          ></textarea>
                          <div class="d-flex justify-content-end w-100">
                            <button class="send-comment">ثبت</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal replay comment -->
          </div>
        </div>

        <hr class="comment-admin-hr" />

        <P class="text-white">
          {{ nl2br(val.message) }}
        </P>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      question: null,
      answer: "",
    };
  },
  created() {
    let _this = this;
    axios({
      method: "post",
      url: "/panel/get-tickets",
      data: { id: _this.id },
    })
      .then((response) => {
        _this.question = response.data;
      })
      .catch((err) => {
        _this.$store.commit("switchLoading", false);
        let errors = err.response.data.errors;
        // old_password err
        if (errors.hasOwnProperty("old_password")) {
          _this.message(errors.old_password[0]);
          _this.old_password.className = "border border-danger";
        }
      });
  },
  methods: {
    message(message, type = "error") {
      Vue.$toast.open({
        message: message,
        type: type,
        position: "top-left",
        duration: 5000,
        // all other options
      });
    },
    nl2br(str, is_xhtml) {
      return str;
    },
    submitForm(event) {
      let _this = this;
      _this.$store.commit("switchLoading", true);
      axios({
        method: "post",
        url: "/panel/set-answer",
        data: { answer: _this.answer, id: _this.id },
      })
        .then((response) => {
          _this.$store.commit("switchLoading", false);
          _this.message("جواب شما با موفقیت ثبت شد", "success");
          setTimeout(function () {
            location.reload();
          }, 1500);
        })
        .catch((err) => {
          _this.$store.commit("switchLoading", false);
          let errors = err.response.data.errors;
          // answer err
          if (errors.hasOwnProperty("answer")) {
            _this.message(errors.answer[0]);
          }
        });
    },
    closeTicket() {
      let _this = this;
      _this.$store.commit("switchLoading", true);
      axios({
        method: "post",
        url: "/panel/close-ticket",
        data: { answer: _this.answer, id: _this.id },
      })
        .then((response) => {
          _this.$store.commit("switchLoading", false);
          _this.message("تیکت مورد نظر بسته شد", "success");
          setTimeout(function () {
            location.href = "/panel/supports";
          }, 1500);
        })
        .catch((err) => {
          /*_this.$store.commit('switchLoading', false);
                    let errors = err.response.data.errors;
                    // answer err
                    if (errors.hasOwnProperty('answer')) {
                        _this.message(errors.answer[0]);
                    }*/
        });
    },
  },
};
</script>
