<template>

    <div class="f-search">
        <p class="text-muted">برای اطلاع از جدیدترین دروس و تخفیف ها ایمیل خود را وارد کنید :</p>
        <form class="position-relative">
            <input type="text" class="newsletters" name="newsletters" v-model="email"
                   placeholder="ایمیل خود را وارد کنید">
            <label></label>
            <button type="submit" class="btn btn-newsletters" @click.prevent="save()">ثبت ایمیل</button>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                email: ""
            }
        },
        methods: {
            message(message, type = 'error') {
                Vue.$toast.open({
                    message: message,
                    type: type,
                    position: "top-left",
                    duration: 5000
                    // all other options
                });
            },
            save() {
                let _this = this;
                _this.$store.commit('switchLoading', true);
                axios({
                    method: "post",
                    url: '/save-news-email',
                    data: {
                        email: _this.email
                    }
                }).then((response) => {
                    _this.$store.commit('switchLoading', false);
                    _this.email = '';
                    _this.message('ایمیل شما در خبرنامه با موفقیت ثبت شد', 'success');
                }).catch((err) => {
                    _this.$store.commit('switchLoading', false);
                    let errors = err.response.data.errors;
                    // email err
                    if (errors.hasOwnProperty('email')) {
                        _this.message(errors.email[0]);
                    }
                });
            }
        }
    }
</script>