<template>
    <div class="container">
        <div class="shopping-table zminus">
            <div class="table-responsive">
                <table
                    class="table table-bordered m-0"
                    v-if="cart.length > 0 && isLogin == 'yes'"
                >
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col" class="shop-title">نام محصول شما</th>
                        <th scope="col" class="shop-title">قیمت</th>
                        <th scope="col" class="shop-title">تعداد</th>
                        <th scope="col" class="shop-title">قیمت مجموع</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="shopping-tr" v-for="(val, index) in cart">
                        <th scope="row" class="text-center">
                            <i
                                class="far fa-times-circle removeIc"
                                @click.prevent="removeFromCart(val.id)"
                            ></i>
                        </th>
                        <td><img :src="val.image" :alt="val.title"/></td>
                        <td>
                            <div class="shopping-product">
                                <a :href="val.link" target="_blank">{{ val.title }}
                                    <span class="discount-text"
                                          v-if="val.discount_code != '' || val.visitor_discount_code != ''">
                      تخفیف اعمال شد
                  </span>
                                </a>
                                <form
                                    v-if="
                      val.discount_code == '' &&
                      val.visitor_discount_code == '' &&
                      parseInt(val.price) != 0
                    "
                                    @submit.prevent="setDiscount($event, index)"
                                >
                                    <div class="discount-code">
                                        <input type="text" name="code" placeholder="کد تخفیف"/>
                                        <button>اعمال کد تخفیف</button>
                                    </div>
                                </form>
                            </div>
                        </td>
                        <td class="shoping-money">
                            <span v-if="val.discount_code == '' &&
                      val.visitor_discount_code == ''">{{
                                    numberWithCommas(
                                        parseInt(val.price) == 0
                                            ? parseInt(val.price)
                                            : val.getType == "post"
                                            ? parseInt(val.price) + parseInt(val.post_price)
                                            : val.getType == "flash"
                                                ? parseInt(val.price) + parseInt(val.flash_price)
                                                : parseInt(val.price)
                                    )
                                }}
                            تومان
                            </span>
                            <div v-else>
                                <s class="text-danger">{{
                                        numberWithCommas(
                                            parseInt(val.price) == 0
                                                ? parseInt(val.price)
                                                : val.getType == "post"
                                                ? parseInt(val.price) + parseInt(val.post_price)
                                                : val.getType == "flash"
                                                    ? parseInt(val.price) + parseInt(val.flash_price)
                                                    : parseInt(val.price)
                                        )
                                    }}
                                    تومان</s>
                                <br>
                                <span>
                                    {{
                                        numberWithCommas(
                                            parseInt(val.price) - ((parseInt(val.price) / 100) * val.percent)
                                            +
                                            (val.getType == "post" ? parseInt(val.post_price) : val.getType == "flash" ? parseInt(val.flash_price) : 0)
                                        )
                                    }}
                                    تومان</span>
                            </div>
                        </td>
                        <td>
                            <select
                                class="how-much"
                                v-model="val.count"
                                @change="changeCount(index)"
                            >
                                <option value="1">1 تعداد</option>
                                <option value="2">2 تعداد</option>
                                <option value="3">3 تعداد</option>
                                <option value="4">4 تعداد</option>
                                <option value="5">5 تعداد</option>
                            </select>
                        </td>
                        <td class="shoping-money">
                            <span v-if="val.discount_code == '' &&
                      val.visitor_discount_code == ''">
                                {{
                                    numberWithCommas(
                                        parseInt(val.price) == 0
                                            ? parseInt(val.price)
                                            : val.getType == "post"
                                            ? parseInt(val.price) + parseInt(val.post_price)
                                            : val.getType == "flash"
                                                ? parseInt(val.price) + parseInt(val.flash_price)
                                                : parseInt(val.price)
                                    )
                                }}
                            </span>
                            <span v-else>
                                    {{
                                    numberWithCommas(((parseInt(val.price) - ((parseInt(val.price) / 100) * val.percent))
                                            +
                                            (val.getType == "post" ? parseInt(val.post_price) : val.getType == "flash" ? parseInt(val.flash_price) : 0)
                                        )
                                        * val.count
                                    )
                                }}
                                    تومان</span>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div class="basket-shopping2" v-if="isLogin == 'no'">
                    <i class="fas fa-lock"></i>
                    <span class="empty-basket">شما وارد نشده اید</span>
                    <span class="more-learn"
                    >برای مشاهده سبد خرید باید وارد سایت بشید</span
                    >
                    <div class="mt-5">
                        <a
                            href="#"
                            data-toggle="modal"
                            data-target="#my-login-modal"
                            class="btn h-signup btn-info p-3"
                        >
                            ثبت نام
                        </a>
                        <a
                            href="#"
                            data-toggle="modal"
                            data-target="#my-login-modal"
                            class="btn h-signin btn-success p-3"
                        >
                            ورود
                        </a>
                    </div>
                </div>

                <div
                    class="basket-shopping2"
                    v-if="cart.length == 0 && isLogin == 'yes'"
                >
                    <i class="nova icon-shopping-bag"></i>
                    <span class="empty-basket">سبد خرید شما خالی است</span>
                    <span class="more-learn">آموزش های بیشتری را اضافه کنید</span>
                    <div class="mt-5">
                        <a href="/course" class="btn btn-success p-3">مشاهده همه دوره ها</a>
                        <button class="btn btn-danger p-3" @click.prevent="myReload()">
                            بروزرسانی سبد خرید
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="update-shopping" v-if="cart.length > 0 && isLogin == 'yes'">
            <button class="update-basket" @click.prevent="myReload()">
                بروزرسانی سبد خرید
            </button>
            <div class="up-money">
                <span class="price"> مجموع</span>
                <span class="all-price"
                >{{ numberWithCommas(cartTotal.total) }} تومان</span
                >
                <!-- <span class="price" v-if="cartTotal.isPost=='yes'">هزینه ارسال</span>
                        <span class="all-price"
                              v-if="cartTotal.isPost=='yes'">{{ numberWithCommas(cartTotal.postPrice) }} تومان</span> -->
            </div>
            <div class="up-money">
                <span class="price">قیمت نهایی</span>
                <span class="all-price"
                >{{ numberWithCommas(cartTotal.total) }} تومان</span
                >
            </div>
        </div>

        <div class="update-shopping" v-if="cart.length > 0 && isLogin == 'yes'">
            <!-- <strong class="text-danger">به ازای تمام پکیج های خریداری شده فقط یک بار قیمت پست محاسبه میشود</strong> -->
            <a href="/cart/final" class="update-basket payment">ادامه و پرداخت</a>
        </div>
    </div>
</template>

<script>
export default {
    props: ["isLogin"],
    data() {
        return {};
    },
    mounted() {
        this.$store.commit("updateCart", true);
        this.$store.commit("getTotal");
        this.$forceUpdate();
    },
    methods: {
        message(message, type = "error") {
            Vue.$toast.open({
                message: message,
                type: type,
                position: "top-left",
                duration: 5000,
                // all other options
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        myReload() {
            this.$store.commit("updateCart", true);
            this.$store.commit("getTotal");
        },
        removeFromCart(id) {
            let _this = this;
            Swal.fire({
                title: "حذف دوره",
                text: "آيا از حذف این دوره از سبد خرید اطمینان دارید؟",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "آره حذف کن",
                cancelButtonText: "نه",
            }).then((result) => {
                if (result.value) {
                    _this.$store.commit("switchLoading", true);
                    axios({
                        method: "post",
                        url: "/cart/remove",
                        data: {
                            id: id,
                        },
                    })
                        .then((response) => {
                            _this.$store.commit("switchLoading", false);
                            _this.$store.commit("updateCart");
                            _this.$store.commit("getTotal");
                            _this.message("دوره مورد نظر حذف شد", "success");
                            _this.$forceUpdate();
                        })
                        .catch((err) => {
                            _this.$store.commit("switchLoading", false);
                            _this.message("خطا در ذخیره سازی اطلاعات");
                        });
                }
            });
        },
        changeCount(index) {
            let _this = this;

            _this.$store.commit("switchLoading", true);
            axios({
                method: "post",
                url: "/cart/change-count",
                data: {
                    id: _this.cart[index].id,
                    count: _this.cart[index].count,
                },
            })
                .then((response) => {
                    _this.$store.commit("switchLoading", false);
                    _this.$store.commit("updateCart");
                    _this.$store.commit("getTotal");
                    _this.$forceUpdate();
                })
                .catch((err) => {
                    _this.$store.commit("switchLoading", false);
                    _this.message("خطا در ذخیره سازی اطلاعات");
                });
        },
        setDiscount(event, index) {
            let _this = this;

            _this.$store.commit("switchLoading", true);
            let form_data = new FormData(event.target);
            form_data.append("id", _this.cart[index].id);
            axios({
                method: "post",
                url: "/cart/set-discount",
                data: form_data,
            })
                .then((response) => {
                    _this.$store.commit("switchLoading", false);
                    switch (response.data) {
                        case "notFound":
                            _this.message("کوپن مورد نظر یافت نشد");
                            break;
                        case "overflow":
                            _this.message("محدودت استفاده از کوپن تمام شده است");
                            break;
                        case "expired":
                            _this.message("این کوپن منقظی شده است");
                            break;
                        case "dd":
                            _this.message(
                                "دوره در جشنواره هست و امکان استفاده از این تخفیف وجود ندارد"
                            );
                            break;
                        case "success":
                            _this.$store.commit("updateCart");
                            _this.$store.commit("getTotal");
                            _this.$forceUpdate();
                            break;
                        default:
                            _this.message("خطا در ذخیره سازی اطلاعات");
                    }
                })
                .catch((err) => {
                    _this.$store.commit("switchLoading", false);
                    _this.message("خطا در ذخیره سازی اطلاعات");
                });
        },
    },
    computed: {
        cart() {
            return this.$store.state.cart;
        },
        cartTotal() {
            return this.$store.state.cartTotal;
        },
    },
};
</script>


<style>
.removeIc {
    cursor: pointer;
    -webkit-transition: ease 300ms;
    -moz-transition: ease 300ms;
    -ms-transition: ease 300ms;
    -o-transition: ease 300ms;
    transition: ease 300ms;
}

.removeIc:hover {
    color: #c42d2a !important;
}

.zminus {
    z-index: 0 !important;
}
</style>
