"use strict";
$(document).ready(function () {


    // menu-responsive
    $(".toggle-canvas").click(function () {
        $(".offset-canvas").toggleClass("show-canvas");
    });
    $(".offset-canvas ul a").click(function () {
        $(".offset-canvas").removeClass("show-canvas");
    });
    $(".offset-canvas .close-nav").click(function () {
        $(".offset-canvas").removeClass("show-canvas");
    });

    $('body').click(function (e) {
        $('.offset-canvas').removeClass('show-canvas');
    })

    $('.offset-canvas, .toggle-canvas').click(function (e) {
        e.stopPropagation()
    })
    // menu-responsive


    // owlCarousel comment
    $('.owl-comment-nav , .owl-single-pod').owlCarousel({
        loop: false,
        margin: 20,
        rtl: true,
        dots: true,
        nav: false,
        items: 3,
        autoplay: false,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 3,
            }
        }
    });
    $('.owl-top').owlCarousel({
        margin: 0,
        rtl: true,
        dots: false,
        nav: false,
        autoplay: false,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 4,
            },
            1200: {
                items: 5,
            }
        }
    });
    // owlCarousel comment

    // owlCarousel single article
    $('.owl-single-article').owlCarousel({
        loop: false,
        margin: 20,
        rtl: true,
        dots: true,
        nav: false,
        items: 5,
        autoplay: false,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 4,
            }
        }
    });
    // owlCarousel single article


    // counter
    var a = 0;
    $(window).scroll(function () {

        if ($('#counter,.counter-value').length) {
            var oTop = $('#counter').offset().top - window.innerHeight;
            if (a == 0 && $(window).scrollTop() > oTop) {
                $('.counter-value').each(function () {
                    var $this = $(this),
                        countTo = $this.attr('data-count');
                    $({
                        countNum: $this.text()
                    }).animate({
                            countNum: countTo
                        },

                        {

                            duration: 2000,
                            easing: 'swing',
                            step: function () {
                                $this.text(Math.floor(this.countNum));
                            },
                            complete: function () {
                                $this.text(this.countNum);
                                //alert('finished');
                            }

                        });
                });
                a = 1;
            }
        }

    });

    $('.owl-counter').owlCarousel({
        loop: false,
        margin: 0,
        rtl: true,
        dots: true,
        nav: false,
        items: 4,
        autoplay: false,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            }
        }
    });
    // counter


    // login avatar
    $('.mini-photo-wrapper').click(function (e) {
        e.preventDefault();
        $('.basket-container').removeClass('active');
        $('.menu-container').toggleClass('active');
        $('.menu-container2').removeClass('active');
    })

    $('body').click(function (e) {
        $('.menu-container').removeClass('active');
    })

    $('.mini-photo-wrapper,.basket-container').click(function (e) {
        e.stopPropagation();
    })

    $('.mini-photo-wrapper2').click(function (e) {
        $('.menu-container2').toggleClass('active');

    })
    // login avatar


    // sabad kharid
    $('.my-basket').click(function (e) {
        e.preventDefault();
        $('.menu-container').removeClass('active');
        $('.basket-container').toggleClass('active');
        $('.basket-container2').removeClass('active');
    });
    $('.new-basket-open').click(function (e) {
        e.preventDefault();
        $('.menu-container').removeClass('active');
        $('.basket-container2').toggleClass('active');
        $('.basket-container1').removeClass('active');
    });
    $('body').click(function (e) {
        $('.basket-container').removeClass('active');
    })

    $('.my-basket,.basket-container,.user-menu-link,.new-basket-open').click(function (e) {
        e.stopPropagation()
    })
    // sabad kharid


    // course accordion
    function toggleIcon(e) {
        $(e.target).prev('.panel-heading').find(".more-less").toggleClass('fa-caret-left fa-caret-down');
    }

    $('.panel-group').on('hidden.bs.collapse', toggleIcon);
    $('.panel-group').on('shown.bs.collapse', toggleIcon);
    // course accordion


    // forget pass

    $(".forget-pass").on("click", function () {
        $("#my-login-modal").modal('hide');
        setTimeout(function () {
            $("#my-login-modal2").modal('show');
        }, 305);
    });

    // back forget pass
    $(".come-back").on("click", function () {
        $("#my-login-modal2").modal("hide");
        setTimeout(function () {
            $("#my-login-modal").modal("show");
        }, 305);
    });
    // back forget pass

    // forget pass


    // modal login tab
    $(".h-signup").on("click", function () {
        // $('#signup-click').trigger('click');
        $(".kh-rm-active").removeClass('active');
        $("#menu1").addClass('active');
        $("#signup-click").addClass('active');
    });

    $(".h-signin").on("click", function () {
        // $('#signin-click').trigger('click');
        $(".kh-rm-active").removeClass('active');
        $("#home").addClass('active');
        $("#signin-click").addClass('active');
    });
    // modal login tab

    $(".kh-reload-c").click(function (e) {
        globalVue.updateArticles();
    });
    $(".kh-reload-c2").click(function (e) {

    });


    // owlCarousel 404
    $('.owl-404').owlCarousel({
        loop: false,
        margin: 0,
        rtl: true,
        dots: true,
        nav: false,
        items: 6,
        autoplay: false,
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 3,
            },
            600: {
                items: 4,
            }
        }
    });
    // owlCarousel 404

// fix-menu

    $(window).scroll(function () {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > 210) {
            $('.header-sticky').addClass('animated');
            $('.header-sticky').addClass('fadeInDown');
            $('.header-sticky').removeClass('fadeOutUp');
            $('.header-sticky').removeClass('my-hide');
            $('.menu-container').removeClass('active');
            $('.basket-container').removeClass('active');
        } else {
            $('.header-sticky').addClass('fadeOutUp');
            $('.header-sticky').removeClass('fadeInUp');
            $('.header-sticky').removeClass('fadeInDown');
        }
    });
// fix-menu

// avatar
    if (document.getElementById('my_canvas') != null) {
        var ctx = document.getElementById('my_canvas').getContext('2d');
        window.al = 0;
        var start = 4.72;
        var cw = ctx.canvas.width;
        var ch = ctx.canvas.height;
        var diff;
        window.progressSim = function () {
            diff = ((al / 100) * Math.PI * 2 * 10).toFixed(2);
            ctx.clearRect(0, 0, cw, ch);
            ctx.lineWidth = 5;
            ctx.fillStyle = '#09F';
            ctx.strokeStyle = "orange";
            ctx.textAlign = 'center';
            ctx.font = "14px IRANSans-English";
            ctx.fillStyle = "black";
            ctx.beginPath();
            ctx.arc(70, 70, 60, start, diff / 10 + start, false);
            ctx.stroke();
            if (window.al >= $("#my_canvas").attr('data-percent')) {
                clearTimeout(window.sim);
                // Add scripting here that will run when progress completes
            }

            //  ctx.fillText(al+'%', cw*.5+5, ch*.5+2, cw);
            $('#my_canvas_text').html(window.al + '%');
            window.al++;
        };

        window.sim = setInterval(progressSim, 30);
    }

// avatar


    function isVisible(elment) {
        var vpH = $(window).height(),
            st = $(window).scrollTop(),
            y = $(elment).offset().top;

        return y <= (vpH + st);
    }

    $(window).scroll(function () {
        if (isVisible($('.copyright'))) {
            $('.responsive-add-cart').css('position', 'absolute');

        } else {
            $('.responsive-add-cart').css('position', 'fixed');
            $('.responsive-add-cart').css('top', 'auto');
        }
    })

    new SimpleLightbox('.gallery a');


// kh sidebar

    $(document).on("scroll", onScrollKh);

    //smoothscroll
    $('.kh-sticky-sidebar a').on('click', function (e) {
        e.preventDefault();
        $('.kh-sticky-sidebar a').removeClass('active');
        $(this).addClass('active');
        let $target = $($(this).attr('href'));
        // location.hash = $(this).attr('href');
        $('html, body').animate({
            'scrollTop': $target.offset().top - 100
        }, 300, 'linear', function () {
            $(document).on("scroll", onScrollKh);
        });
    });


// kh saidbar
});

function onScrollKh() {
    var scrollPos = $(document).scrollTop();
    $('.kh-sticky-sidebar a').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
            $('.kh-sticky-sidebar ul li a').removeClass("active");
            currLink.addClass("active");
        } else {
            currLink.removeClass("active");
        }
    });
}


$("body").on("click", ".mytoggle", function () {
    if ($(this).html() == "پنهان") {
        $(this).html("نمایش");
    } else {
        $(this).html("پنهان");
    }
    $(".table__list").toggleClass("list-invisible");
    $(".table-of-contents").toggleClass("table-narrow");

})

if ($(".box-read-title").length > 0) {
    var text = $(".box-read-title").html();
    $(".box-read-title").remove();

    $(".podcast-post p").each(function () {
        if ($.trim($(this).html())) {
            $(this).append(text);
            return false;
        }
    });


    var num = 0;
    var num2 = 1;
    $(".podcast-post h2, .podcast-post h3").each(function () {
        if ($(this).is("h2")) {
            num += 1;
            $(this).attr("id", "h2-" + num);
            $(".table__list").append('<li class="table__list-item"><span class="content__number"></span> <a href="#h2-' + num + '" class="table__link">' + $(this).text() + '</a><ul class="table__nested-list child-item' + num + '"></ul></li>')
            num2 = 1;
        } else {
            $(this).attr("id", "h3-" + num + '-' + num2);
            $(".child-item" + num).append('<li class="nested-list__item"><span class="content__number"></span><a href="#h3-' + num + '-' + num2 + '" class="table__link">' + $(this).text().replace(/\d+/g, "") + '</a></li>\n')
            num2 += 1;
        }
    });
}

$("body").on("click", "a.table__link", function (e) {
    e.preventDefault();
    var _this = $(this);
    $('html, body').animate({
        scrollTop: $(_this.attr("href")).offset().top - 150
    }, 1000);
})
