/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import SimpleLightbox from "simplelightbox";

require('./bootstrap-front');

window.Vue = require('vue');
Vue.use(require('vue-resource'));
window.axios = require('axios')


import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/index.css';

Vue.use(VueToast);

import VueSpinners from 'vue-spinners'

Vue.use(VueSpinners)
import VueX from 'vuex';

Vue.component('square', VueSpinners.SquareSpinner)
Vue.config.productionTip = true;


import VueW3CValid from 'vue-w3c-valid';


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/CreateTagForm.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
Vue.component('pulse-loader', require('vue-spinner/src/PulseLoader.vue'));
Vue.component('login', require('./components/front/Login.vue').default);
Vue.component('register', require('./components/front/Register.vue').default);
Vue.component('add-comment', require('./components/front/AddComment.vue').default);
Vue.component('add-contact', require('./components/front/AddContact.vue').default);
Vue.component('forget-password', require('./components/front/ForgetPassword.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


import store from './store';
import loading from "./components/loading";
import userProfile from './components/panel/profile';
import changePassword from './components/panel/change-pass';
import newSupport from './components/panel/new-ticket';
import support from './components/panel/ticket';
import shoppingCart from './components/front/shopping-cart';
import shoppingCart2 from './components/front/shopping-cart2';
import shoppingCartShow from './components/front/shopping-cart-show';
import finalCart from './components/front/final-cart';
import singleCourseSide from './components/front/singleCourseSide';
import search from './components/front/search';
import searchMobile from './components/front/search-mobile';
import newsRegister from './components/front/newsRegister';
import singleCourseSideMobile from './components/front/singleCourseSideMobile';
import registerEvent from "./components/front/registerEvent";
import Swal from 'sweetalert2';
import grv3 from './components/googlerecaptchav3/GoogleReCaptchaV3.vue';

window.Swal = Swal;

Vue.use(VueX);
Vue.component('loading', loading);
Vue.component('user-profile', userProfile);
Vue.component('change-user-pass', changePassword);
Vue.component('support', support);
Vue.component('new-support', newSupport);
Vue.component('shoppingCart', shoppingCart);
Vue.component('shoppingCart2', shoppingCart2);
Vue.component('singleCourseSide', singleCourseSide);
Vue.component('shopping-cart-show', shoppingCartShow);
Vue.component('final-cart', finalCart);
Vue.component('search', search);
Vue.component('searchMobile', searchMobile);
Vue.component('newsRegister', newsRegister);
Vue.component('singleCourseSideMobile', singleCourseSideMobile);
Vue.component('registerEvent', registerEvent);
Vue.component('google-re-captcha-v3', grv3);

new VueW3CValid({
    el: '#app'
});

$("#kh-add-search").html('<search></search>');
$("#kh-add-login").html('<login data-v-if="refreshed2"></login>');
$("#kh-add-register").html('<register data-v-if="refreshed"></register>');
$("#kh-add-loading").html('<loading></loading>');

window.globalVue = new Vue({
    el: '#app',
    data: {
        refreshed: true,
        refreshed2: true,
        refreshed3: true,
        siteKey: "6Lco9QUbAAAAAAGXQhcNVw6UbSfWq03BMoQKax4e",
        isShowC: true,
        rTokenC: ""
    },
    store,
    mounted() {
        let _this = this
        this.isShowC = false;
        this.$nextTick(() => {
            _this.isShowC = true;
        })
    },
    methods: {
        refreshMdl(type) {
            var self = this;
            if (type === 1) {
                self.refreshed = false;
                Vue.nextTick(function () {
                    self.refreshed = true;
                });
            } else if (type === 2) {
                self.refreshed2 = false;
                Vue.nextTick(function () {
                    self.refreshed2 = true;
                });
            } else {
                self.refreshed3 = false;
                Vue.nextTick(function () {
                    self.refreshed3 = true;
                });
            }

        },
        myCopy(event) {
            var TextToCopy = $(event.target).text();
            var TempText = document.createElement("input");
            TempText.value = TextToCopy;
            document.body.appendChild(TempText);
            TempText.select();

            document.execCommand("copy");
            document.body.removeChild(TempText);

            Vue.$toast.open({
                message: `کد لایسنس شما کپی شد`,
                type: "success",
                position: "top-left",
                duration: 5000,
                // all other options
            });
        }
    }
});


// countdown
if (window.nowDate && window.endDate) {
    var NnowDate = window.nowDate.split('/');
    var now = new Date(parseInt(NnowDate[0]), parseInt(NnowDate[1]), parseInt(NnowDate[2]), parseInt(NnowDate[3]), parseInt(NnowDate[4]), parseInt(NnowDate[5]));

    function countdown() {
        var NendDate = window.endDate.split('/');
        var eventDate = new Date(parseInt(NendDate[0]), parseInt(NendDate[1]), parseInt(NendDate[2]), parseInt(NendDate[3]), parseInt(NendDate[4]));

        var currentTime = now.getTime();
        var eventTime = eventDate.getTime();

        var remTime = eventTime - currentTime;

        var s = Math.floor(remTime / 1000);
        var m = Math.floor(s / 60);
        var h = Math.floor(m / 60);
        var d = Math.floor(h / 24);

        h %= 24;
        m %= 60;
        s %= 60;

        h = (h < 10) ? "0" + h : h;
        m = (m < 10) ? "0" + m : m;
        s = (s < 10) ? "0" + s : s;

        // $("#days").html(d);
        // $("#hours").html(h);
        // $("#minutes").html(m);
        // $("#seconds").html(s);

        document.getElementById("days").textContent = d;
        document.getElementById("hours").textContent = h;
        document.getElementById("minutes").textContent = m;
        document.getElementById("seconds").textContent = s;

        now.setTime(now.getTime() + 1000);
        setTimeout(countdown, 1000);
    }

    countdown();
}


// countdown
/*$(window).scroll(function (e) {
    if (window.scrollY <= 150) {
        $('.basket-container').removeClass('active');
    }
});*/
setInterval(function () {
    if (window.scrollY <= 150) {
        $('.basket-container2').removeClass('active');
    }
}, 100);


$('.khCpBtn').click(function (e) {
    e.preventDefault();
    $(this).parent().find('span').trigger('click');
});


$('.top-alert-bar .close').click(function (e) {
    e.preventDefault();
    $(this).parent().parent().remove();
})

if ($('.alb-timer').length > 0) {

    var main = $($('.alb-timer').find('.albt-inner')[0]);
    var endTimestamp = main.data('expire');
    var compareDate = new Date(endTimestamp);
    setInterval(function () {
        timeBetweenDates(compareDate);
    }, 1000);

    function timeBetweenDates(toDate) {
        var dateEntered = toDate;
        var now = new Date();
        var currentTime = now.getTime();
        var eventTime = dateEntered.getTime();

        var timeleft = eventTime - currentTime;

        var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);


        $(main.find('.days .num')[0]).text(days == "-1" ? "0" : days);
        $(main.find('.hours .num')[0]).text(hours == "-1" ? "0" : hours);
        $(main.find('.minutes .num')[0]).text(minutes == "-1" ? "0" : minutes);
        $(main.find('.seconds .num')[0]).text(seconds == "-1" ? "0" : seconds);


    }
}


import lozad from 'lozad';

const observer = lozad();
observer.observe();
