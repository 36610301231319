<template>
    <div class="kh_loading" v-if="loading">
        <div class="kh_loading_body">
            <div class="nb-spinner"></div>
        </div>
    </div>
</template>

<script>
    import store from '../store';

    export default {
        computed: {
            loading: () => {
                return store.state.loading;
            }
        }
    };
</script>

<style>
    .kh_loading {
        background: rgba(0, 0, 0, 0.67);
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 999999999;
    }

    .kh_loading_body {
        width: 150px;
        height: 150px;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        z-index: 9999999;
    }

    .nb-spinner {
        width: 100%;
        height: 100%;
        margin: 0;
        background: transparent;
        border-top: 4px solid rgba(0, 112, 187, 0.9);
        border-right: 4px solid transparent;
        border-radius: 50%;
        -webkit-animation: 1s spin linear infinite;
        animation: 1s spin linear infinite;
    }

    @-webkit-keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes spin {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

</style>